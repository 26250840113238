import { wrapPageElementShared } from "./gatsby-shared";

import "@fontsource/open-sans"; // Defaults to weight 400 with normal variant.

let loaded = false;

const createRoute = (location) => {
    return location ? location.pathname + location.search + location.hash : undefined;
}

export const onRouteUpdate = (data) => {
    const { location, prevLocation } = data;

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    };

    // if (window.location.hostname !== 'localhost') {
        if (!loaded) {
            gtag('js', new Date());
            gtag('config', 'G-DETTSK3CLK', { page_path: createRoute(location) })

            loaded = true;
        } else {
            gtag('event', 'navigate',
                {
                    cur_route: createRoute(location),
                    prev_route: createRoute(prevLocation),
                }
            );
        }
    // }
};

// Wraps every page in a component
export const wrapPageElement = (props) => {
    return wrapPageElementShared(props);
};